.app {
    font-family: sans-serif;
}

.app {
    background-color: #635860
}

.active {
    background-color: #0A2239 !important;
}

.navbar {
    background-color: #0A2239 !important;
}

.card {
    padding: 0 !important;
    margin-top: 20px !important;
    margin-bottom: 30px !important;
    background-color: #635860 !important;
    border-color: #777 !important;
}

.card.inline {
    margin-left: 30px;
    height: fit-content;
    cursor: pointer;
}

.modal-content {
    background-color: #635860 !important;
    border-color: #777 !important;
}

.modal-content img {
    max-width: 100% !important;
}

.list-group-item {
    background-color: #635860 !important;
    border-color: #777 !important;
}

.card-header {
    background-color: #432840 !important;
    padding-top: 15px !important;
    border-color: #777 !important;
}

.kb-item {
    margin-bottom: 20px;
    padding-left: 30px;
}

.card-body a {
    color: #62B1F6 !important;
}

.kb-section {
    margin-bottom: 20px;
}

.kb-item img {
    max-width: 100%;
}

.wizard li:hover {
    background-color: #aaa !important;
}

.btn {
    background-color: #176087 !important;
    border-color: #002855 !important;
    color: white !important;
}

.btn:hover {
    background-color: #1D84B5 !important;
    color: white !important;
}

.long-definition h3 {
    font-size: 1.3rem;
}

.long-definition h4 {
    font-size: 1.2rem;
}

.long-definition h5 {
    font-size: 1.1rem;
}

.long-definition a {
    color: #999
}

.login {
    height: calc(100vh - 70px);
    padding: 100px;
    text-align: center;
}

.google button {
    background-color: #176087 !important;
    border-color: #002855 !important;
    color: white !important;
}

.google button {
    background-color: #176087 !important;
    border-color: #002855 !important;
    color: white !important;
}

.google button div {
    background-color: #176087 !important;
}

iframe {
    margin: auto !important;
}